import React, { useEffect, useState } from "react";
import Button from "./Button";
import Popup from "./Popup";
import api from "./api";
import "./App.css";

const App = () => {
  const [response, setResponse] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [closeButton, setCloseButton] = useState(false);
  const [confirmButton, setConfirmButton] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [ordernumbers, setOrderNumbers] = useState("");

  useEffect(() => {
    console.log("Response is " + response);
    if (response) {
      setCloseButton(true);
      setPopupMessage(response);
    } else {
      setCloseButton(false);
    }
  }, [response]);

  const handleButtonClick = (action) => {
    setConfirmButton(true);
    setCloseButton(true);
    setPopupVisible(true);
    setPopupMessage(`Are you sure you want to ${action}?`);
    setCurrentAction(action);
  };

  const confirmAction = async () => {
    setConfirmButton(false);
    setCloseButton(false);
    setPopupMessage("Working on it...");
    setResponse("");

    try {
      if (currentAction === "Update Sheet") {
        setResponse(await api.clearSheet());
      } else if (currentAction === "Send Pending Messages") {
        setResponse(await api.sendPendingMessages());
      } else if (currentAction === "Process Through Swift") {
        setResponse(await api.processOrders());
      } else if (currentAction === "Process Through Smartr") {
        setResponse(await api.processOrdersSmartr());
      } else if (currentAction === "Process Through Shiprocket") {
        setResponse(await api.processOrdersShiprocket());
      } else if (currentAction === "Process Through Bluedart") {
        setResponse(await api.processOrdersBluedart());
      } else if (currentAction === "Add New COD Orders") {
        setResponse(await api.addCODOrders());
      } else if (currentAction === "Add Orders") {
        setResponse(await api.addOrders(ordernumbers));
      }
    } catch (error) {
      setResponse("Error: " + error.message);
    }
  };

  const closePopup = () => {
    setPopupVisible(false);
    setPopupMessage("");
    setCurrentAction("");
  };

  return (
    <div>
      <header>
        <img src="Logo.png" alt="Company Logo" />
      </header>
      <main>
        <Button onClick={() => handleButtonClick("Update Sheet")}>
          Update Sheet
        </Button>
        <Button onClick={() => handleButtonClick("Send Pending Messages")}>
          Send Pending Messages
        </Button>
        <div className="seperator">
          <div
            style={{ backgroundColor: "black", width: "150px", height: "2px" }}
          ></div>
        </div>
        <Button onClick={() => handleButtonClick("Process Through Smartr")}>
          Process Through Smartr
        </Button>
        <Button onClick={() => handleButtonClick("Process Through Bluedart")}>
          Process Through Bluedart
        </Button>
        <Button onClick={() => handleButtonClick("Process Through Swift")}>
          Process Through Swift
        </Button>
        <Button onClick={() => handleButtonClick("Process Through Shiprocket")}>
          Process Through Shiprocket
        </Button>
        <div className="seperator">
          <div
            style={{ backgroundColor: "black", width: "150px", height: "2px" }}
          ></div>
        </div>
        <Button onClick={() => handleButtonClick("Add New COD Orders")}>
          Add New COD Orders
        </Button>
        <div className="seperator">
          <div
            style={{ backgroundColor: "black", width: "150px", height: "2px" }}
          ></div>
        </div>
        <input onChange={(e) => setOrderNumbers(e.target.value)}></input>
        <Button onClick={() => handleButtonClick("Add Orders")}>
          Add Orders to Sheet
        </Button>

        {popupVisible && (
          <Popup
            message={popupMessage}
            onClose={closePopup}
            showClose={closeButton}
            onConfirm={confirmAction}
            showConfirm={confirmButton}
          />
        )}
      </main>
    </div>
  );
};

export default App;
