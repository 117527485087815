import React from "react";
import "./Popup.css";

const Popup = ({ message, onClose, showClose, onConfirm, showConfirm }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <p style={{ whiteSpace: "pre-line" }} className="popup-message">
          {message}
        </p>
        {showClose && (
          <button onClick={onClose} className="popup-close">
            Close
          </button>
        )}
        {showConfirm && (
          <button onClick={onConfirm} className="popup-confirm">
            Confirm
          </button>
        )}
      </div>
    </div>
  );
};

export default Popup;
