import axios from "axios";

const BASE_URL = "https://vpg5603owk.execute-api.ap-south-1.amazonaws.com/api"; // Replace with your API base URL

const api = {
  clearSheet: async () => {
    try {
      // console.log(`${BASE_URL}/cleansheetshopify/False`);
      const response = await axios.get(`${BASE_URL}/cleansheetshopify/False`);
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to clear sheet");
    }
  },
  sendPendingMessages: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/pendingorders`);
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to send pending messages");
    }
  },
  processOrders: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/orderprocessing/Swift`);
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to process orders");
    }
  },
  processOrdersSmartr: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/orderprocessing/Smartr`);
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to process orders");
    }
  },
  processOrdersShiprocket: async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/orderprocessing/Shiprocket`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to process orders");
    }
  },
  processOrdersBluedart: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/orderprocessing/Bluedart`);
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to process orders");
    }
  },
  addCODOrders: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/cleansheetshopify/True`);
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to process orders");
    }
  },
  addOrders: async (orders) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/getorders/${encodeURIComponent(orders)}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error("Failed to process orders");
    }
  },
};

export default api;
